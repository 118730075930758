<template>
	<div ref="wrapper" :class="{ show: show }" class="sidebar-wrapper">
		<div class="sidebar">
			<router-link
				:class="{ active: route.name === 'Home' }"
				:to="{ name: 'Home' }"
				@click="$emit('hide')"
			>
				Home
			</router-link>
			<router-link :to="{ path: '/' }" @click="onScrollTestimonial">
				<span>Testimonials</span>
			</router-link>
			<router-link
				:class="{ active: route.name === 'Projects' }"
				:to="{ name: 'Projects' }"
				@click="$emit('hide')"
			>
				Projects
			</router-link>
			<router-link
				:class="{ active: route.name === 'Technology' }"
				:to="{ name: 'Technology', params: {} }"
				@click="$emit('hide')"
			>
				Technologies
			</router-link>
			<router-link
				:class="{ active: route.name === 'Blog' }"
				:to="{ name: 'Blog', params: {} }"
				@click="$emit('hide')"
			>
				Blog
			</router-link>
			<!-- <router-link
				:class="{ active: route.name === 'Case Studies' }"
				:to="{ name: 'Case Studies', params: {} }"
				@click="$emit('hide')"
			>
				Case Studies
			</router-link> -->
			<router-link
				:class="{ active: route.name === 'TeamPage' }"
				:to="{ name: 'TeamPage' }"
				@click="$emit('hide')"
			>
				Team
			</router-link>
			<router-link
				class="apply-btn"
				:to="{ name: 'QuoteContact' }"
				@click="$emit('hide')"
			>
				Get a free quote
			</router-link>

			<div class="socials">
				<a
					target="_blank"
					href="https://ae.linkedin.com/company/enon-solutions"
				>
					LinkedIn
				</a>
				<div class="split"></div>
				<a target="_blank" href="https://www.instagram.com/enonsolutions/">
					Instagram
				</a>
			</div>
		</div>
	</div>
</template>

<script>
import { useRoute } from "vue-router";
import { ref, defineEmits } from "vue";
export default {
	name: "SidebarComp",
	props: ["show"],
	emits: ["hide"],
	setup(props, ctx) {
		const route = useRoute();

		function onScrollTestimonial() {
			setTimeout(() => {
				const el = document.getElementById("testimonial");
				let coorY = 0;
				if (el) {
					coorY = el.offsetTop - 20;
				}
				window.scrollTo({
					top: coorY,
					behavior: "smooth",
				});
			}, 10);
			ctx.emit("hide");
		}

		return {
			route,
			onScrollTestimonial,
		};
	},
};
</script>

<style lang="scss" scoped>
.sidebar-wrapper {
	z-index: 2;
	position: fixed;
	top: $top-page-nav-height;
	height: 100%;
	bottom: 0;
	left: -100vw;
	width: 100vw;
	transition-duration: 0.3s;

	.sidebar {
		width: 100%;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		z-index: 6;
		background: var(--es-background-color);
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 20px 0 0;

		a {
			font-weight: 500;
			font-size: 34px;
			line-height: 37px;
			color: #fff;
			text-decoration: none;
			margin-bottom: 1rem;
			transition-duration: 0.3s;
		}

		a.active {
			color: #23c07e;
		}

		a:hover {
			color: #23c07e;
		}

		.socials {
			position: absolute;
			bottom: 34px;
			left: 0;
			width: 100vw;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-evenly;

			.split {
				width: 1px;
				height: 48px;
				background: #d5dce2;
			}

			a {
				font-weight: 400;
				font-size: 20px;
				line-height: 22px;
				color: #62737c;
				margin-bottom: 0;
			}
		}

		.apply-btn {
			background: $app-color;
			border-radius: $button-border-radius;
			height: $button-height;
			width: 160px;
			color: #fff;
			font-weight: 600;
			font-size: 18px;
			line-height: 18px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			transition-duration: 0.3s;
		}

		.apply-btn:hover {
			opacity: 0.9;
		}
	}
}

.show {
	left: 0;
}
</style>
