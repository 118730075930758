<template>
	<div class="blog-wrapper">
		<div class="blog-heading-wrapper">
			<h2>Transforming Businesses with IT Solutions</h2>
			<h3>
				Explore how our cutting-edge IT services help businesses thrive in a
				digital world.
			</h3>
		</div>
		<div v-if="!posts" class="blog-posts-wrapper">
			<div v-for="(_, index) in 8" :key="index" class="blog-post-wrapper">
				<div class="blog-post-cover-wrapper">
					<div class="blog-post-cover"></div>
				</div>

				<div class="blog-post-info-wrapper">
					<div class="blog-post-title"></div>
					<div class="blog-post-date"></div>
				</div>
			</div>
		</div>
		<div v-else class="blog-posts-wrapper">
			<router-link
				v-for="post in posts"
				:key="post.pst_id"
				class="blog-post-wrapper"
				:to="{ name: 'BlogPost', params: { slug: post.pst_slug } }"
			>
				<div class="blog-post-cover-wrapper">
					<div
						class="blog-post-cover"
						:style="{ backgroundImage: `url(${post.thumbnail})` }"
					></div>
				</div>

				<div class="blog-post-info-wrapper">
					<div class="blog-post-title">{{ post.pst_title }}</div>
					<div class="blog-post-date">
						{{ store.utcToLocale(post.pst_utc_datetime, true) }}
					</div>
				</div>
			</router-link>
		</div>
		<div class="btn-wrapper">
			<div v-if="showLessBool" class="btn" @click="showMore('less')">
				Show Less
			</div>
			<div v-if="showMoreBool" class="btn btn-next" @click="showMore('more')">
				Show More
			</div>
		</div>
		<inline-svg class="glow-img" :src="glowImg"></inline-svg>
	</div>
</template>

<script setup lang="ts">
import animateElementsOnScroll from "@/js/intersection-observer.v2.js";
import glowImg from "@/assets/images/upgraded/glow.svg";
import { useDefaultStore } from "@/store";

interface Post {
	pst_id: number;
	pst_title: string;
	pst_slug: string;
	pst_description: string | null;
	pst_utc_datetime: string;
	pst_utc_deleted: string | null;
	sta_code: string;
	thumbnail: string | null;
	total_count?: number;
}

const store = useDefaultStore();
// const route = useRoute();
const posts = ref<Post[] | null>(null);
const showMoreBool = ref(true);
const showLessBool = ref(false);
const currentPage = ref(1);
const startLimit = ref(8);
const limit = ref(8);

async function getPosts() {
	limit.value = startLimit.value * currentPage.value;
	console.log(limit.value);
	console.log(currentPage.value);

	const params = {
		limit: limit.value,
	};

	try {
		const res = await axios.get(`${store.blogRootApi}posts/posts-get`, {
			params,
		});

		if (posts.value && posts.value.length > 0 && posts.value[0].total_count) {
			showMoreBool.value = posts.value[0].total_count > limit.value;
		}
		if (currentPage.value > 1) {
			showLessBool.value = true;
		} else {
			showLessBool.value = false;
		}

		posts.value = res.data.data;
		console.warn(res.data.data);
	} catch (e: any) {
		console.error(e);
	}
}

// Show more

async function showMore(type: string) {
	if (type == "less") {
		currentPage.value -= 1;
	} else if (type == "more") {
		currentPage.value += 1;
	}

	await getPosts();
}

onMounted(async () => {
	animateElementsOnScroll(
		".blog-wrapper",
		"slideFromBot 1s 0s forwards",
		".blog-heading-wrapper, .blog-posts-wrapper, .btn-wrapper",
	);
	await getPosts();
});
</script>

<style lang="scss" scoped>
.blog-wrapper {
	padding: ($top-page-nav-height + 40px) 0 20px;
	position: relative;
	min-height: 100vh;

	.btn-wrapper {
		display: flex;
		justify-content: center;
		.btn {
			margin: 48px 0;
			width: 200px;
			z-index: 2;

			&.btn-next {
				background: $app-color;
				margin-left: 1rem;
			}
		}
	}

	.blog-heading-wrapper {
		width: 100%;
		max-width: 1500px;
		margin: 0 auto;
		margin-bottom: 50px;
		padding: 0 20px;

		h2 {
			font-style: normal;
			font-weight: 600;
			margin-bottom: 48px;
			text-align: center;
			font-size: 32px;
			line-height: 40px;
		}

		h3 {
			text-align: center;
			font-size: 15px;
			font-style: normal;
			font-weight: 400;
			color: $second-app-color;
		}
	}

	.blog-posts-wrapper {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		.blog-post-wrapper {
			cursor: pointer;
			width: 100%;
			margin-bottom: 2rem;
			background: linear-gradient(
				180deg,
				rgba(19, 24, 31, 0) 60%,
				#13181f 100%
			);
			overflow: hidden;
			display: flex;
			flex-direction: column;
			color: unset;

			.blog-post-cover-wrapper {
				width: 100%;
				aspect-ratio: 100 / 55.55;
				overflow: hidden;

				.blog-post-cover {
					height: 100%;
					background-position: top center;
					background-repeat: no-repeat;
					background-size: cover;
					transition: transform 0.5s ease;
					transform: scale(1);
				}
			}

			.blog-post-info-wrapper {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				flex: 1;

				.blog-post-title {
					font-size: 24px;
					line-height: 28px;
					font-weight: 600;
					padding: 20px;
					transition: color 0.3s ease;
					color: unset;
				}

				.blog-post-date {
					padding: 0 20px 20px;
					font-size: 14px;
					color: unset;
				}
			}

			&:hover {
				.blog-post-cover-wrapper {
					.blog-post-cover {
						transform: scale(1.05);
					}
				}

				.blog-post-info-wrapper {
					.blog-post-title {
						color: $second-app-color;
					}
				}
			}
		}
	}

	.glow-img {
		position: absolute;
		margin: auto;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		max-width: 100%;
		z-index: -1;
	}
}

@media screen and (min-width: 768px) {
	.blog-wrapper {
		padding: ($top-page-nav-height + 200px) 20px 5rem 20px;
		max-width: 1500px;
		margin: 0 auto;

		.btn-wrapper {
			.btn {
				&.btn-next {
					margin-left: 12px;
				}
			}
		}

		.blog-heading-wrapper {
			margin-bottom: 100px;

			h2 {
				font-size: 54px;
				line-height: 48px;
			}

			h3 {
				font-size: 25px;
			}
		}

		.blog-posts-wrapper {
			display: grid;
			grid-template-columns: repeat(2, minmax(350px, 1fr));
			gap: 12px;
		}
	}
}

@media screen and (min-width: 1224px) {
	.blog-wrapper {
		.blog-posts-wrapper {
			grid-template-columns: repeat(3, minmax(350px, 1fr));
			gap: 24px;
		}

		.btn-wrapper {
			.btn {
				&.btn-next {
					margin-left: 24px;
				}
			}
		}
	}
}

@media screen and (min-width: 1576px) {
	.blog-wrapper {
		.blog-posts-wrapper {
			grid-template-columns: repeat(4, minmax(350px, 1fr));
		}
	}
}
</style>
