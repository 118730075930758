<script lang="ts" setup>
import { useDefaultStore } from "@/store";
import animateElementsOnScroll from "@/js/intersection-observer.v2.js";
import glowImg from "@/assets/images/upgraded/glow.svg";

const route = useRoute();
const store = useDefaultStore();
const post = ref<any>(null);

async function getIdFromPath() {
	const params = route.params as { slug: string | string[] };

	const slug = ref("");

	if (Array.isArray(params.slug)) {
		slug.value = params.slug[0];
	} else {
		slug.value = params.slug;
	}
	await getPosts(slug.value);
}

async function getPosts(pst_slug: string) {
	const params = {
		pst_slug,
	};

	try {
		const res = await axios.get(`${store.blogRootApi}posts/posts-get`, {
			params,
		});
		if (res.data.data && res.data.data.length > 0) {
			post.value = res.data.data[0];
		}
		// console.log(res.data.data[0]);
	} catch (err: any) {
		// console.log(err.response.data.message);
		console.warn(err.message);
	}
}

onMounted(async () => {
	animateElementsOnScroll(".post-page-wrapper", "slideFromBot 1s 0s forwards");
	await getIdFromPath();
});
</script>

<template>
	<div class="post-page-wrapper">
		<div class="post-page-inner-wrapper">
			<div class="post-page-title-wrapper">
				<h1 v-if="post">{{ post.pst_title }}</h1>
			</div>
			<div
				class="post-page-poster-wrapper"
				:style="{ backgroundImage: `url(${post ? post.poster : null})` }"
			></div>
			<div class="post-page-date-wrapper">
				{{ store.utcToLocale(post ? post.pst_utc_datetime : null, true) }}
			</div>
			<p
				class="post-page-content-wrapper"
				v-html="post ? post.pst_content : null"
			></p>
			<inline-svg class="glow-img" :src="glowImg"></inline-svg>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.post-page-wrapper {
	.post-page-inner-wrapper {
		padding: ($top-page-nav-height + 40px) 0 20px;
		position: relative;
		min-height: 100vh;

		.post-page-title-wrapper {
			width: 100%;
			max-width: 1500px;
			margin: 0 auto;
			margin-bottom: 50px;
			padding: 0 20px;

			h1 {
				font-style: normal;
				font-weight: 600;
				margin-bottom: 48px;
				text-align: center;
				font-size: 32px;
				line-height: 40px;
			}
		}

		.post-page-poster-wrapper {
			width: 100%;
			max-width: 1500px;
			margin: 0 auto;
			margin-bottom: 50px;
			aspect-ratio: 100 / 55.55;
			background-position: top center;
			background-repeat: no-repeat;
			background-size: cover;
		}

		.post-page-date-wrapper {
			font-size: 12px;
			margin: 0 auto;
			margin-bottom: 25px;
			width: 100%;
			max-width: 1500px;
			padding: 0 20px;
			color: $second-app-color;
		}

		.post-page-content-wrapper {
			width: 100%;
			max-width: 1500px;
			margin: 0 auto;
			padding: 0 20px;
			font-size: 15px;
			line-height: 1.8;
			font-weight: 400;
			margin-bottom: 1.2rem;
			// color: $section-color;
		}

		.glow-img {
			position: absolute;
			margin: auto;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			max-width: 100%;
			z-index: -1;
		}
	}
}

@media screen and (min-width: 768px) {
	.post-page-wrapper {
		.post-page-inner-wrapper {
			padding: ($top-page-nav-height + 200px) 0 5rem;
			max-width: 1500px;
			margin: 0 auto;

			.post-page-title-wrapper {
				margin-bottom: 100px;
				padding: 0 20px;

				h1 {
					font-size: 54px;
					line-height: 48px;
				}
			}

			.post-page-date-wrapper {
				font-size: 12px;
			}

			.post-page-content-wrapper {
				font-size: 18px;
			}
		}
	}
}

@media screen and (min-width: 1540px) {
	.post-page-wrapper {
		.post-page-inner-wrapper {
			.post-page-date-wrapper {
				padding: 0;
			}

			.post-page-content-wrapper {
				padding: 0;
			}
		}
	}
}
</style>
